.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}
.main {
    height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom, #fff, #f39419);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .heading {
    text-align: center;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin: 30px auto 15px auto;
  }
  .button {
    width: 80%;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 15px auto;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .linkA {
    text-align: center;
    text-decoration: none;
    color: #f39419;
    font-size: 14px;
    font-weight: 600;
    padding: 20px auto;
  }
  .icon {
    display: flex;
    flex-direction: row;
    margin: 30px 80px;
    justify-content: center;
  }
  .iconD {
    width: 35px;
    height: 35px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 30px;
    padding: 7px;
    margin-right: 10px;
  }
  .iconSpan {
    width: 20px;
    height: 20px;
    color: #f39419;
  }
  
  /* Enable hover only on non-touch devices */
  @media (hover: hover) and (pointer: fine) {
    .card:hover {
      background: rgba(var(--card-rgb), 0.1);
      border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }
  
    .card:hover span {
      transform: translateX(4px);
    }
  }
  
  @media (prefers-reduced-motion) {
    .card:hover span {
      transform: none;
    }
  }
  
  /* Mobile */
  @media (max-width: 700px) {
    .content {
      padding: 4rem;
    }
    .container {
      width: 100%;
    }
  
    .grid {
      grid-template-columns: 1fr;
      margin-bottom: 120px;
      max-width: 320px;
      text-align: center;
    }
  
    .card {
      padding: 1rem 2.5rem;
    }
  
    .card h2 {
      margin-bottom: 0.5rem;
    }
  
    .center {
      padding: 8rem 0 6rem;
    }
  
    .center::before {
      transform: none;
      height: 300px;
    }
  
    .description {
      font-size: 0.8rem;
    }
  
    .description a {
      padding: 1rem;
    }
  
    .description p,
    .description div {
      display: flex;
      justify-content: center;
      position: fixed;
      width: 100%;
    }
  
    .description p {
      align-items: center;
      inset: 0 0 auto;
      padding: 2rem 1rem 1.4rem;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
      background: linear-gradient(
        to bottom,
        rgba(var(--background-start-rgb), 1),
        rgba(var(--callout-rgb), 0.5)
      );
      background-clip: padding-box;
      backdrop-filter: blur(24px);
    }
  
    .description div {
      align-items: flex-end;
      pointer-events: none;
      inset: auto 0 0;
      padding: 2rem;
      height: 200px;
      background: linear-gradient(
        to bottom,
        transparent 0%,
        rgb(var(--background-end-rgb)) 40%
      );
      z-index: 1;
    }
  }
  
  /* Tablet and Smaller Desktop */
  @media (min-width: 701px) and (max-width: 1120px) {
    .grid {
      grid-template-columns: repeat(2, 50%);
    }
  }
  
  @media (prefers-color-scheme: dark) {
    .vercelLogo {
      filter: invert(1);
    }
  
    .logo {
      filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  
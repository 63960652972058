.container {
    background-color: #fff;
    text-align: center;
    height: 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container img {
    width: 70px;
}
@media screen and (min-width: 1200px) {
    .container {
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 999;
    }
    .container img {
        width: auto;
    } 
}
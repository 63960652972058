/* =====constant============== */
.container {
  background: #000;
  height: 100%;
  width: 100%;
}
.desktopImage {
  display: none;
}
.blank {
  background: #000;
  height: 20px;
}
.button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
  color: #474747;
}
.wrapper {
  height: 100%;
  width: 100%;
}
.text_wrapper {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.big_text {
  color: #fff;
  font-weight: 700;
  text-align: center;
}
.small_text {
  color: #fff;
  font-weight: 400;
  text-align: center;
}
.allicon span {
  margin: 0px 6px;
  cursor: pointer;
}
.form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.label_inut {
  display: flex;
  flex-direction: column;
}
.label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-top: 24px;
  margin-bottom: 9px;
}
.input {
  width: 340px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #fff;
  /* margin-bottom: 24px; */
  color: #474747;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 11px;
}
.flex_two_input {
  display: flex;
}
.flex_two_input_label {
  display: flex;
  flex-direction: column;
}
.input_1 {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #000;
  margin-bottom: 24px;
  color: #474747;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 11px;
}
.button {
  width: 361px;
  height: 50px;
  border-radius: 10px;
  background: #f4961d;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 24px;
  cursor: pointer;
}
.success_message {
  align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    background: #06060685;
    color: #fff;
    height: 100%;
    z-index: 1000;
}
.submitted{
  color: #474747;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 6px;
}
.submitted_2{
  color: #474269b3;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
}
.success_box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  width: 487px;
  height: 445px;
  border-radius: 10px;
}
.delete {
  color: #fff;
  cursor: pointer;
  background: #F39419;
  border: none;
  outline: none;
  transition: 1s;
  top: 22px;
  position: absolute;
  right: 22px;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
} 
 .delete:hover{
  transform: scale(0.9);
}

.success_icon {
  /* width: 300px; */
  margin-bottom: 25px;
}
.errorLine{
  color: red;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: 4px;
  left: 4px;
}
.inputError{
  border:  2px solid red;
}
/* =====600px============= */
@media only screen and (max-width: 600px) {
  .success_box {
    width: 270px;
    height: 290px;
}
.delete {
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
.delete img{
  width: 8px;
}
.submitted_2 {
  font-size: 12px;
  padding: 0px 43px;
}
.submitted {
  font-size: 14px;
}
.success_icon{
  width: 151px;
}


  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .text_wrapper {
    width: 100%;
    height: 240px;
    position: relative;
    top: -60px;
    background-image: url('../assets/background.png');
  }
  .big_text {
    font-size: 18px;
    top: 33px;
    position: relative;
    top: 110px;
  }
  .small_text {
    font-size: 12px;
    padding: 0px 64px;
    position: relative;
    top: 100px;
  }
  .vactor {
    width: 150px;
    left: 30px;
    top: 98px;
    position: absolute;
  }
  .form_wrapper {
    width: 100%;
    align-items: center;
    position: relative;
    top: -65px;
  }
  .form {
    width: 320px;
    height: 450px;
  }
  .label {
    margin-left: 8px;
    color: #fff;
  }
  .input {
    width: 310px;
    height: 50px;
  }
  .flex_two_input_label {
    background: none;
  }
  .input_1 {
    width: 136px;
  }
  .button {
    width: 325px;
  }
  .allicon {
    margin: 60px 0px;
  }
  .allicon span {
    margin: 0px 10px;
  }
  .allicon span.clicked {
    color: #f4961d;
  }
  .footer {
    height: 68px;
  }
}
/* ====767 to 913px========== */
@media only screen and (min-width: 767px) and (max-width: 913px) {
  .text_wrapper {
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -70px;
    background-image: url('../assets/background.png');
  }
  .blank_2 {
    height: 160px;
  }
  .big_text {
    font-size: 30px;
  }
  .small_text {
    font-size: 18px;
    padding: 0px 115px;
    margin-top: 10px;
  }
  .vactor {
    width: 230px;
    top: 147px;
    left: 46px;
    position: absolute;
  }
  .form_wrapper {
    justify-content: none;
    align-items: center;
    height: 70vh;
    position: relative;
    top: -115px;
  }
  .form {
    width: 450px;
    height: 490px;
    background: #fff;
  }
  .allicon {
    margin-top: 50px;
  }
  .allicon span {
    margin: 0px 15px;
  }
}
/* ==========913 to 1024px======== */
@media only screen and (min-width: 913px) and (max-width: 1024px) {
  .success_box {
    width: 450px;
    height: 380px;
  }
  .success_icon {
    width: 250px;
  }
  .wrapper {
    display: flex;
  }
  .blank_2 {
    height: 42px;
  }
  .big_text {
    font-size: 30px;
    position: relative;
    top: 35px;
    z-index: 10;
  }
  .small_text {
    font-size: 18px;
    padding: 0px 77px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    top: 35px;
  }
  .vactor {
    display: none;
  }
  .background_image {
    background-image: url('../assets/background.png');
    height: 380px;
    background-repeat: no-repeat;
    background-size: contain;
}
.vactor_1 {
  width: 243px;
    top: 155px;
    left: 47px;
    position: absolute;
}
.desktopImage {
  display: block;
  position: relative;
  top: -70px;
}
  .form_wrapper {
    align-items: center;
    width: 765px;
    position: relative;
  }
  .form {
    width: 450px;
    height: 460px;
    background: #fff;
  }
  .allicon {
    position: absolute;
    bottom: 1px;
    right: 60px;
  }
}
/* ======1200px======= */
@media only screen and (min-width: 1200px) {
  .wrapper {
    display: flex;
  }
  .blank_2 {
    height: 42px;
  }
  .big_text {
    font-size: 38px;
    position: relative;
    top: 60px;
  }
  .background_image {
    background-image: url('../assets/background.png');
    height: 530px;
    background-repeat: no-repeat;
    background-size: contain;
}
.vactor_1 {
  width: 328px;
  top: 213px;
  left: 64px;
  position: absolute;
}
.desktopImage {
  display: block;
  position: relative;
}
  .small_text {
    font-size: 24px;
    padding: 0px 70px;
    margin-top: 10px;
    position: relative;
    top: 60px;
    z-index: 9;
  }
  .vactor {
    display: none;
  }
  .form_wrapper {
    align-items: center;
    width: 1000px;
    height: 715px; 
    position: relative;
  }
  .form {
    width: 450px;
    height: 460px;
    background: #fff;
  }
  .allicon {
    position: absolute;
    bottom: 20px;
    right: 60px;
  }
}
/* ======1400px======== */
@media screen and (min-width: 1400px) {
.success_icon{
  width: 300px;
}
  .blank {
    height: 30px;
  }
  .wrapper {
    display: flex;
  }
  .text_wrapper {
    width: 800px;
  }
  .background_image {
    background-image: url('../assets/background.png');
    height: 590px;
}
.vactor_1 {
  width: 365px;
  top: 240px;
  left: 72px;
  position: absolute;
}
.desktopImage {
  display: block;
  width: 800px;
}
  .blank_2 {
    height: 120px;
  }
  .big_text {
    font-size: 38px;
    position: relative;
    top: 5px;
  }
  .small_text {
    font-size: 24px;
    padding: 0px 110px;
    margin-top: 13px;
    position: relative;
    top: 10px;
  }
  .vactor {
      display: none;
  }
  .form_wrapper {
    align-items: center;
    width: 1102px;
    height: 860px;
    position: relative;
  }
  .form {
    width: 450px;
    height: 460px;
    background: #fff;
  }
  .allicon {
    position: absolute;
    bottom: 20px;
    right: 60px;
  }
}


/* mobile first */
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.itemWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}
.logoImg { 
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}
.heading {
    text-align: center;
    color: #faa12b;
    font-weight: 700;
    margin: 30px auto 15px auto;
}

.btnText {
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 20px auto;
}
.textwrapper {
    width: 100%;
    border: 1px solid #faa12b;
    border-radius: 5px;
    padding: 10px;
    margin: 20px 0px;
}
.stepsHeading {
    text-align: left;
    color: #faa12b;
    font-weight: 700;
}
.stepsList {

}

.stepsItem {

}

.btnContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.appBtn {
    width: 75%;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 0px;
    margin: 15px;
    background-color: #474747;
    text-align: center;
    cursor: pointer;
}
.appBtnText {
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 10px auto;
}

.coupon {
    width: 360px;
    height: 360px;
}

.linkText {
    text-align: center;
    text-decoration: underline;
    color: #0082df;
    font-size: 16px;
    font-weight: 600;
    margin: 20px;
    padding: 0px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
}

.topLogo {
    width: 60px;
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    /* background-color: #0082df; */
}


/* desktop view */

@media only screen and (min-device-width : 768px) {
    
    .topLogo {
        width: 100px;
    }
    .logoImg { 
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }
    .heading {
        text-align: center;
        color: #faa12b;
        font-weight: 700;
        margin: 30px auto 15px auto;
    }
    .button {
        width: 80%;
        height: 45px;
        border-radius: 5px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border: 0px;
        margin: 15px auto;
        background-color: #faa12b;
        text-align: center;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .appBtn {
        width: 70%;
        height: 45px;
        border-radius: 5px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border: 0px;
        margin: 20px;
        text-align: center;
        cursor: pointer;
    }

    .appBtnText {
        font-size: 14px;
        font-weight: 600;
        padding: 20px auto;
    }
    
}
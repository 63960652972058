.header {
    text-align: center;
    height: 8vh;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    z-index: 999;
    align-items: center;
  }
  
  .headerImage {
    max-width: 100px;
    height: 50px;
    display: block; 
    margin: 0 auto;
    padding: 8px;
  }
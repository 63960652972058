.container {
  background: #4eb6c1;
}
.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.form {
  background: #fff;
  width: 830px;
  height: 800px;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
}
.label_inut {
  display: flex;
  flex-direction: column;
  padding: 7px 15px;
}
.label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  position: relative;
}
.input {
  width: 340px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #00000073;
  color: #000;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding-left: 11px;
}
.input::placeholder {
  color: #4747475d;
  font-weight: 300;
}
.input_1 {
  width: 212px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #00000073;
  color: #000;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 11px;
}
.input_1::placeholder {
  color: #4747475d;
  font-weight: 300;
}
.input:focus,
.input:active {
  border: 2px solid #4eb6c1;
}
.input_1:focus,
.input_1:active {
  border: 2px solid #4eb6c1;
}
.button {
  width: 361px;
  height: 50px;
  border-radius: 10px;
  background: #f4961d;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border: none;
  outline: none;
  margin-top: 15px;
  cursor: pointer;
  margin-left: -30px;
}
.inputError {
  border: 2px solid red;
}
.errorLine {
  color: red;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: 4px;
  left: 4px;
}
.button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
  color: #000;
}

/* ================ */

.success_message {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  background: #06060685;
  color: #fff;
  height: 100%;
  z-index: 1000;
}
.submitted {
  color: #474747;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 6px;
}
.submitted_2 {
  color: #474269b3;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
}
.success_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  width: 487px;
  height: 445px;
  border-radius: 10px;
}
.delete {
  color: #fff;
  cursor: pointer;
  background: #f39419;
  border: none;
  outline: none;
  transition: 1s;
  top: 22px;
  position: absolute;
  right: 22px;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete:hover {
  transform: scale(0.9);
}

.success_icon {
  /* width: 300px; */
  margin-bottom: 25px;
}
.wrapper_three_coloum {
  display: flex;
}
.selectedSection {
  width: 355px;
  height: 53px;
  border-radius: 5px;
  border: 1px solid #00000073;
  /* color: #4747475d; */
  outline: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 11px;
}
.selectedSection option {
  min-height: 50px;
}
.color {
  color: #4747475d !important;
  font-weight: 300 !important;
}
.star {
  top: 4px;
  position: absolute;
  padding: 0px 5px;
}
@media only screen and (max-width: 600px) {
  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    flex-wrap: nowrap;
  }
  .form_wrapper {
    height: 100%;
    padding: 70px 0px;
  }
  .input {
    width: 290px;
  }
  .selectedSection {
    width: 300px;
  }
  .button {
    width: 290px;
    width: 305px;
    margin-left: -2px;
  }
  .success_box {
    width: 310px;
    height: 390px;
  }
  .success_icon {
    width: 200px;
  }
  .wrapper_three_coloum {
    flex-direction: column;
  }
  .input_1 {
    width: 290px;
  }
}

/* ====767 to 913px========== */
@media only screen and (min-width: 767px) and (max-width: 913px) {
  .form {
    width: 650px;
    height: 730px;
  }
  .input {
    padding: 7px 10px;
    width: 260px;
  }
  .selectedSection {
    width: 270px;
  }
  .button {
    width: 290px;
    width: 305px;
    margin-left: 5px;
  }
  .wrapper_three_coloum {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 15px;
  }
  .input_1 {
    width: 154px;
    padding: 7px 10px;
  }
}
/* ==========913 to 1024px======== */
@media only screen and (min-width: 913px) and (max-width: 1024px) {
  .form_wrapper {
    height: 100%;
    padding: 40px 0;
  }
}
